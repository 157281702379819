<template>
<div class="container">
  <div class="head">
    <HeaderView :bgColor="bgColor"></HeaderView>
  </div>
    <div class="banner">
        <div class="banner_text">
            <h1>资讯中心</h1>
            <p>获取行业最新动态</p>
        </div>
    </div>
    <div class="auto_box">
      <div class="title_box">
        <p>当前位置：首页>新闻资讯>详情</p>
      </div>
      <div class="news_main">
        <h1>{{detail.title}}</h1>
        <div class="newsTime">文章来源：{{detail.source}}  发布时间：{{detail.publishtime}}</div>
        <div class="news_content" v-html="detail.content"></div>
      </div>
      <div class="prev_next">
        <p v-if="detail.prev" @click="prev">上一篇：{{detail.prev.title}}</p>
        <p v-if="detail.next" @click="next">下一篇：{{detail.next.title}}</p>
      </div>
    </div>
</div>
</template>
<script>
import { getNewsDetail } from '@/request/api'
import HeaderView from '@/components/headerView.vue'
export default {
  name: 'newsDetail',
  components: { HeaderView },
  data(){
    return{
      bgColor:'rgba(235,229,225,.8)',
      detail:{}, // 内容
      id:null,
    }
  },
  created(){
    this.id = this.$route.query.id
  },
  mounted(){
    this.getNewsDetail()
  },
  methods:{
    getNewsDetail(){
      getNewsDetail({id:this.id}).then((res)=>{
        this.detail = res.data
      })
    },
    prev(){
      this.id = this.detail.prev.id
      this.getNewsDetail()
    },
    next(){
      this.id = this.detail.next.id
      this.getNewsDetail()
    },
  }
}
</script>
<style scoped lang="less">
.banner{
    width:1920px;
    height:471px;
    position: relative;
    background: url('../assets/images/newsBanner.jpg')no-repeat;
    background-size:100% 100%;
    .banner_text{
      width:1200px;
      margin:0 auto;
      padding-top:170px;
      padding-left:80px;
      text-align:left;
      color: #000000;
      animation: banner-animation 1s ease-in-out;
    }
    h1{
      height: 40px;
      font-size: 40px;
      margin-bottom:20px;
    }
    p{
      font-size: 30px;
    }
}
.title_box{
  width:1920px;
  height:40px;
  line-height:40px;
  background:#F7F7FB;
  p{
    width:1200px;
    margin:0 auto;
    font-size: 14px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #656565;
  }
}
.news_main{
  width:1200px;
  margin:60px auto 30px;
  h1{
    height: 24px;
    font-size: 24px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #000000;
    text-align:center;
    margin-bottom:20px;
  }
  .newsTime{
    font-size: 14px;
    font-family: SourceHanSansSC-Regular, SourceHanSansSC;
    font-weight: 400;
    color: #656565;
    text-align:center;
  }
  .news_content{
    border-top:1px solid #D3D3D3;
    border-bottom:1px solid #D3D3D3;
    padding:50px 0;
    margin-top:50px;
  }
}
.prev_next{
  width:1200px;
  margin:30px auto 50px;
  p{
    line-height:34px;
    cursor: pointer;
    &:hover{
      color:#E73921;
    }
  }
}
</style>